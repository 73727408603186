import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout";
import Container from "../components/Container";

const Schedule = ({ data }) => {
    return (
        <Layout>
            <title>Home Page</title>
            <Container>
            <h1>Schedule</h1>
            <div className="py-16">
                {data.allContentfulScheduleItem.edges.map(({node}, i) => (
                <div className="py-8 mb-4 text-lg border-b border-gray-300 border-solid" key={i}>
                    <div className="text-xl">{node.title}</div>
                    <div>{node.date_time}</div> 
                    <div>{node.location}</div> 
                </div>
                ))}
            </div>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query ($order: [SortOrderEnum] = ASC) {
        allContentfulScheduleItem(sort: {fields: date_time, order: $order}) {
            edges {
                node {
                    title
                    date_time
                    location
                }
            }
        }
    }
`

export default Schedule
